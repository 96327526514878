<template>
  <!-- Logo -->
  <div class="flexRow">
    <div class="iconContainer">
      <img
        @click="homePage()"
        class="icon"
        src="https://res.cloudinary.com/djb4cocmv/image/upload/v1699007593/Blog-Public/slikaLogo_clm7bw.png"
        alt="Page logo"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    homePage() {
      this.$router.push("/homepage");
    },
  },
};
</script>

<style>
.iconContainer {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
}

.icon {
  height: 220px;
  width: 50%;
  cursor: pointer;
}

.flexRow {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}
</style>
