<!-- BackgroundOverlay.vue -->
<template>
  <div class="overlay"></div>
</template>

<script>
export default {
  name: 'BackgroundOverlay'
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Change the alpha value to adjust the opacity */
  z-index: 1; /* Ensure it's on top of other content */
  pointer-events: none; /* Allows pointer events to pass through the overlay */
}
</style>
