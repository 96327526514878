import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import TheHomepage from "./components/TheHomepage.vue";
import App from "./App.vue";

const NotFound = () => import("./components/NotFound.vue");

const TheDestionations = () => import("./components/TheDestinations.vue");

const ContactUs = () => import("./components/ContactUs.vue");

const TheSmjestaj = () => import("./components/TheSmjestaj.vue");

const SviRestorani = () => import("./svi-restorani/SviRestorani.vue");

const PetiSmrGr = () => import("./components/PetiSmrtniGrijeh.vue");

const TitovaVilaIzvor = () => import("./sve-destinacije/TitovaVilaIzvor.vue");

const BrdoPriKranju = () => import("./sve-destinacije/BrdoPriKranju.vue");

const StariGradOstrozac = () => import("./sve-destinacije/StariGradOstrozac.vue");

const ZeljavaAirBase = () => import("./sve-destinacije/ZeljavaAirBase.vue");

const UtvrdaCetin = () => import("./sve-destinacije/UtvrdaCetin.vue");

const KanjonVintgar = () => import("./sve-destinacije/KanjonVintgar.vue");

const PostojnskaJama = () => import("./sve-destinacije/PostojnskaJama.vue");

const PredjamskiDvorac = () => import("./sve-destinacije/PredjamskiDvorac.vue");

const PetrovaGora = () => import("./sve-destinacije/PetrovaGora.vue");

const NapustenoSeloDolovo = () => import("./sve-destinacije/NapustenoSeloDolovo.vue");

const RusevineGradaDvigrada = () => import("./sve-destinacije/RusevineGradaDvigrada.vue");

const DreznikGrad = () => import("./sve-destinacije/DreznikGrad.vue");

const SeverinNaKupi = () => import("./sve-destinacije/SeverinNaKupi.vue");

const NovigradNaDobri = () => import("./sve-destinacije/NovigradNaDobri.vue");

const TheMaldives = () => import("./sve-destinacije/TheMaldives.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/:notFound(.*)", component: NotFound },
    { path: "/homepage", component: TheHomepage },
    { path: "/", redirect: "homepage" },
    { path: "/destinations", component: TheDestionations },
    { path: "/contact", component: ContactUs },
    { path: "/smjestaj", component: TheSmjestaj },
    { path: "/petismrtnigrijeh", component: PetiSmrGr },
    { path: "/destinations/titovavilaizvor", component: TitovaVilaIzvor },
    { path: "/destinations/brdoprikranju", component: BrdoPriKranju },
    { path: "/destinations/starigradostrozac", component: StariGradOstrozac },
    { path: "/destinations/zeljavaairbase", component: ZeljavaAirBase },
    { path: "/destinations/utvrdacetin", component: UtvrdaCetin },
    { path: "/destinations/kanjonvintgar", component: KanjonVintgar },
    { path: "/destinations/postojnskajama", component: PostojnskaJama },
    { path: "/destinations/predjamskidvorac", component: PredjamskiDvorac },
    { path: "/destinations/petrovagora", component: PetrovaGora },
    { path: "/destinations/napustenoselodolovo",component: NapustenoSeloDolovo },
    { path: "/destinations/rusevinegradadvigrada", component: RusevineGradaDvigrada },
    { path: "/destinations/starigraddreznik", component: DreznikGrad },
    { path: "/destinations/severinnakupi", component: SeverinNaKupi },
    { path: "/destinations/novigradnadobri", component: NovigradNaDobri },
    { path: "/destinations/maldivi", component: TheMaldives },
    { path: "/petismrtnigrijeh/svirestorani", component: SviRestorani },
    { path: "/petismrtnigrijeh/svirestorani", component: SviRestorani },
  ],
});

const app = createApp(App);

// GLOBAL FUNCTIONS

app.mixin({
  methods: {
    fullScreen(event) {
      const imageFullSize = event.target;
      imageFullSize.requestFullscreen();
    },
    faceLink() {
      const pageID =
        "profile.php?id=61552693838673&sk=about_contact_and_basic_info&locale=hr_HR";

      // Construct the URL for the Facebook page
      const facebookPageURL = "https://www.facebook.com/" + pageID;

      // Redirect to the Facebook page
      window.open(facebookPageURL, "_blank");
    },
    instaLink() {
      window.open(
        "https://www.instagram.com/avanture_obicnog_smrtnika/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
      );
    }
  },
});

app.use(router);
app.mount("#app");
