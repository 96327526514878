<template>
  <div class="mainContainer">
    <div class="flexRow">
      <!-- Header -->
      <div class="header">
        <!-- <div class="dropdownContent">
          <button class="flexColumn2 dropdownBtn cursor">
            <div>
              <div class="firstLine"></div>
              <div class="secondLine"></div>
              <div class="thirdLine"></div>
            </div>
          </button>
          <div class="dropdownFlexColumn dropdownItem">
            <a href="#">On-Road</a>
            <a href="#">X-Road</a>
            <a href="#">Off-Road</a>
            <a href="#">BMX</a>
          </div>
        </div> -->

        <!-- /smjestaj -->

        <div class="headerContentStyle">
          <div class="menuFullScreen">
            <router-link to="/destinations"><h3>Destinacije</h3></router-link>
            <router-link to="#"><h3>Smještaj</h3></router-link>
            <router-link to="/petismrtnigrijeh"
              ><h3>Peti smrtni grijeh</h3></router-link
            >
            <router-link to="/contact"><h3>Kontakt</h3></router-link>
          </div>

          <!-- <div class="menu">
            <div style="display: flex !important">
              <input type="text" placeholder="Search..." />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="25"
                height="25"
                viewBox="0 0 24 24"
              >
                <path
                  d="M 9 2 C 5.1458514 2 2 5.1458514 2 9 C 2 12.854149 5.1458514 16 9 16 C 10.747998 16 12.345009 15.348024 13.574219 14.28125 L 14 14.707031 L 14 16 L 20 22 L 22 20 L 16 14 L 14.707031 14 L 14.28125 13.574219 C 15.348024 12.345009 16 10.747998 16 9 C 16 5.1458514 12.854149 2 9 2 z M 9 4 C 11.773268 4 14 6.2267316 14 9 C 14 11.773268 11.773268 14 9 14 C 6.2267316 14 4 11.773268 4 9 C 4 6.2267316 6.2267316 4 9 4 z"
                ></path>
              </svg>
            </div>
          </div> -->
        </div>
      </div>

      <!-- Content -->
      <section style="padding-top: 30px" class="flexRow fontStyle">
        <div class="content">
          <p>Dragi čitatelji...</p>
          <p>
            Ideja o stvaranju ovog bloga nastala je od nas troje običnih
            balkanaca, koji gore od želje za putovanjima. A kao i većina nas
            "običnih smrtnika" na ovom poluotoku, zbog običnih poslova koje
            radimo nismo u mogućnosti priuštiti si stalna putovanja na neke nama
            željene destinacije poput Kariba, Tajlanda, Egipta, New Yorka i
            ostalih, pa vrijeme trošimo na niskobuđetne izlete istraživajući dio
            Europe u kojem živimo i time obuzdajemo svoju glad za istraživanjem.
          </p>
          <p>
            Iza nas je već mnogo kilometara i pustolovina...i nekako nam je žao
            što sve ovo nismo pokrenuli ranije da Vam predočimo koliko zapravo
            ljepote skriva jedan tako mali dio Europe. Da "putovanje" ne znači
            nužno let avionom, odsjedanje u skupom hotelu i obroci u
            restoranima. I da Vam barem djelomično pokažemo koliko ima savršenih
            ideja za kratke izlete koji su udaljeni samo nekoliko sati vožnje...
          </p>
          <p>
            Djelit ćemo sa Vama sva naša obližnja i dalja putovanja koja smo
            imali i koje ćemo tek imati.
          </p>
          <p>
            Ovaj blog je još jedno naše putovanje koje tek počnje i nadamo se da
            ćemo na ovaj način i Vas potaknuti na koje.
          </p>
          <p>Hvala Vam na čitanju. 💞</p>
          <p>I sretan put. 👋</p>
        </div>
        <div>
          <img
            class="contentPhoto"
            src="https://res.cloudinary.com/djb4cocmv/image/upload/v1699008162/Blog-Public/grupnaSlika_rzpaev.png"
            alt=""
          />
        </div>
      </section>
    </div>

    <div id="cookies">
      <!-- Popup Dialog -->
      <div :class="['popup', { show: showPopup }]" class="popup">
        <div class="popupOptions">
          <p v-if="!showList">Do you accept cookies?</p>
          <div v-if="!showList" class="popupBtn">
            <button @click="acceptCookies">Accept</button>
            <button @click="closePopup">Decline</button>
          </div>
          <svg
            v-if="!showList"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            @click="showMore()"
          >
            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"
            />
          </svg>
          <div v-if="showList">
            <li>
              You store a simple cookie to record the user's choice to accept
              cookies.
            </li>
            <li>
              This cookie does not contain personal information and is solely
              used to remember the user's preference for cookie usage.
            </li>
          </div>
          <svg
            v-if="showList"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            @click="showLess()"
          >
            <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M246.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3 361.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160zm160 352l-160-160c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 301.3 361.4 438.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      showPopup: false,
      showList: false,
    };
  },
  mounted() {
    // Check if the user has already accepted cookies
    if (Cookies.get("cookiePreference") === "accepted") {
      this.showPopup = false; // If accepted, don't show the popup
    } else {
      setTimeout(() => {
        this.showPopup = true;
      }, 1000);
    }
  },
  methods: {
    acceptCookies() {
      // Set cookie if user accepts
      Cookies.set("cookiePreference", "accepted", { expires: 92 }); // Expires in 365 days
      this.showPopup = false; // Hide the popup
    },
    closePopup() {
      this.showPopup = false; // Hide the popup if declined
    },
    showMore() {
      this.showList = true;
    },

    showLess() {
      this.showList = false;
    },
  },
};
</script>

<style>
.mainContainer {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 100%;
  padding-top: 20px;
}

.iconContainer {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 10px;
}

.menu {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  color: rgb(45, 45, 45);
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
}

.menuFullScreen {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  color: rgb(45, 45, 45);
  align-items: center;
  height: 100%;
  width: 800px;
  justify-content: space-evenly;
}

.icon {
  height: 220px;
  width: 50%;
}

.content {
  width: 850px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contentPhoto {
  height: 450px;
  width: 300px;
}

.flexColumn {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.flexRow {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

p {
  margin: 0;
  color: rgb(190, 151, 77);
  font-family: "Kalam", cursive;
  font-size: 20px;
  font-weight: normal;
  font-weight: 300;
}

.content p:first-child {
  width: 100%;
}

.content p:nth-of-type(4) {
  width: 100%;
}

a {
  display: flex;
  text-decoration: none;
  font-size: 25px;
  color: rgb(20, 20, 20);
  height: 100%;
  align-items: center;
  font-family: "Kalam", cursive;
  text-decoration: underline;
}

a:hover {
  color: rgb(255, 208, 0);
}

.fontStyle {
  font-size: 20px;
}

.searchBar {
  display: flex !important;
  justify-content: space-evenly;
}

input {
  width: 250px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 10px;
  opacity: 0.6;
}

div > svg {
  padding-left: 5px;
}

div > svg:hover {
  cursor: pointer;
}

.image {
  height: 200px;
  width: 100px;
}

#cookies {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100px;
}

.popup {
  display: flex;
  background-color: rgb(241, 241, 241);
  width: 500px;
  height: 75px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 100%;
  opacity: 0;
  transition: opacity 0.8s;
}

.popup.show {
  opacity: 0.8;
}

#cookies > div > div > p {
  color: black !important;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 5px;
}

.popupBtn {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 5px;
}

#cookies > div > div > div > button {
  border: 1px solid gray;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 20px;
  width: 100px;
}

#cookies > div > div > div > button:hover {
  color: wheat;
  background-color: gray;
}

.popup > div > svg {
  padding: 0;
  height: 15px;
}

.popupOptions {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  height: 100%;
}

.headerContentStyle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

/* HAMBURGER ICON CLASSES FOR MOBILE SCREEN SIZES */

.dropdownFlexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  z-index: 999;
  border: 1px solid transparent;
  border-radius: 2px;
  opacity: 0;
}

.flexColumn2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.dropdownContent {
  /* KEEP DISPLAY NONE IN HERE. IN MEDIA QUERIES IS DISPLAY FLEX */
  display: none;
  flex-direction: column;
  align-items: center;
}

.dropdownContent .firstLine,
.secondLine,
.thirdLine {
  height: 2px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  margin: 4px 0px;
  transition: 0.5s;
}

.dropdownContent > button:hover .firstLine {
  transform: translate(0px, 4.5px) rotate(-45deg);
}

.dropdownContent > button:hover .secondLine {
  opacity: 0;
}

.dropdownContent > button:hover .thirdLine {
  transform: translate(0px, -7px) rotate(45deg);
}

.dropdownContent .dropdownFlexColumn {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

.dropdownContent:hover .dropdownFlexColumn {
  visibility: visible;
  opacity: 0.5;
  transition: 0.5s;
}

.dropdownBtn {
  background-color: rgb(14, 14, 14);
  border-style: none;
}

.dropdownItem {
  height: 200px;
  width: 100px;
  background-color: rgb(14, 14, 14);
  padding-top: 20px;
  margin-top: 20px;
}

.cursor {
  cursor: pointer;
}
</style>
