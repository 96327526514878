<template>
  <div>
    <BackgroundOverlay></BackgroundOverlay>
    <!-- Logo -->
    <base-card-logo></base-card-logo>
    <RouterView></RouterView>
  </div>
</template>

<script>
import BackgroundOverlay from "./background-overlay/BackgroundOverlay"; // Adjust the path based on your project structure
import BaseCardLogo from "../src/base-cards/LogoBaseCard.vue";

export default {
  components: { BackgroundOverlay, BaseCardLogo },
};
</script>

<style>
/* Import your external CSS file */
@import url("/public/media-queries.css");

html,
body {
  position: relative;
  z-index: 1;
}
</style>
